import Axios, { AxiosInstance } from "axios";
import router from "@/router";
import { useAuth } from "@/api-connectors/auth";
import retryInit from "@/composables/useInit";

export const TOKEN_KEY = 'authKey'
export const ADMIN_KEY = 'admin'

const { getToken } = useAuth()

export const API_URL = ENV.API_URL

export function useApi() {
    const api: AxiosInstance = Axios.create()
    let initDone = false

    const init = async () => {
        if (initDone) return
        api.defaults.baseURL = API_URL

        api.interceptors.request.use(async function (config) {
            if (!config.headers) config.headers = {}
            config.headers["Authorization"] = "Bearer " + await getToken();
            return config;
        });

        api.interceptors.response.use(function (response) {
            // Any status code that lie within the range of 2xx cause this function to trigger
            return response;
        }, function (error: { response: { status: number; }; }) {
            if (error.response.status === 401) {
                if (router.currentRoute.meta?.auth) {

                    //! TODO: Implement failed authenticate logic
                    localStorage.removeItem(TOKEN_KEY)
                    // localStorage.removeItem(ADMIN_KEY)
                }
                if (router.currentRoute.meta?.admin) router.replace({ name: 'LoginAdmin' })
                else router.replace({ name: 'Login' })
            }
            return Promise.reject(error);
        });
        initDone = true
    }

    init();

    return {
        api
    }
}