import Vue from 'vue'
import './plugins/bootstrap-vue';
import App from './App.vue'
import { pinia } from "@/plugins/pinia";
import router from "@/router";
import "./theme/global.css";
import './plugins/vue-i18n';
import i18n from "@/plugins/vue-i18n";
import './theme/customTheme.scss';
import './plugins/vee-validate3';
import { useModal } from "@/composables/useModal";


Vue.config.productionTip = false

const thisVue = new Vue({
    pinia,
    router,
    i18n,
    render: h => h(App),
}).$mount('#app')

useModal()

export default thisVue
