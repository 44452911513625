import { ADMIN_KEY, TOKEN_KEY, useApi } from "@/composables/useApi";
import { AxiosResponse } from "axios";
import { useAuth } from "@/api-connectors/auth";
import { LoginOptions } from "@/types/frontend/functionTypes";
import { AuthResult } from "@/types/backend/functionTypes";
import { LoginForm } from "@/types/frontend/eventTypes";
import { B_VARIANT } from "@/types/frontend/enums/bootstrapEnums";
import router from "@/router";
import { popToast } from "@/composables/useToast";

const { api } = useApi()

export function useLogin() {
    const login = async (loginForm: LoginForm): Promise<AuthResult | undefined> => {
        const { formData } = loginForm
        const { authRequest } = useAuth()

        if (!formData.email || !formData.pw) return; //Show Toast empty fields

        return await authRequest('auth/login', { email: formData.email, password: formData.pw })
    }

    const loginUser = async (loginForm: LoginForm, options: LoginOptions) => {
        const { callbackRouteName, admin, callbackRouteParams, callbackRouteQueries } = options

        const response = await login(loginForm)

        if (!response) return popToast({
            content: 'Kein gültiges Login gefunden. Prüfen Sie ob Username und Passwort korrekt sind.',
            title: 'Ungültiges Login',
            variant: B_VARIANT.WARNING
        });

        if (!response.valid) return popToast({
            content: 'User hat keine Berechtigung',
            title: 'Ungültiges Login',
            variant: B_VARIANT.DANGER
        });

        if (admin) {
            if (!response.isAdmin) return popToast({
                content: 'User hat keine Berechtigung',
                title: 'Ungültiges Login',
                variant: B_VARIANT.DANGER
            });
        }

        await router.push({
            name: callbackRouteName,
            params: callbackRouteParams,
            query: callbackRouteQueries
        })
    }

    const logout = async (): Promise<AxiosResponse> => {
        await localStorage.removeItem(TOKEN_KEY)
        await localStorage.removeItem(ADMIN_KEY)
        return api.post('/auth/logout')
    }

    return {
        loginUser,
        logout
    }
}