enum B_VARIANT {
    PRIMARY = 'primary',
    SECONDARY = 'secondary',
    DANGER = 'danger',
    WARNING = 'warning',
    SUCCESS = 'success',
    INFO = 'info',
    DARK = 'dark',

    TERTIARY = 'tertiary',
    FOURTHLY = 'fourthly',
    FIFTHLY = 'fifthly',
    SHADOW = 'shadow'
}

enum B_TABLE_VARIANT {
    LIGHT = 'light',
    DARK = 'dark'
}

enum B_TABLE_SORT {
    ASC = 'asc',
    DESC = 'desc',
    LAST = 'last'
}

enum TOASTER_POSITION {
    TOP_LEFT = 'b-toaster-top-left',
    TOP = 'b-toaster-top-center',
    TOP_RIGHT = 'b-toaster-top-right',
    TOP_FULL = 'b-toaster-top-full',
    BOTTOM_LEFT = 'b-toaster-bottom-left',
    BOTTOM = 'b-toaster-bottom-center',
    BOTTOM_RIGHT = 'b-toaster-bottom-right',
    BOTTOM_FULL = 'b-toaster-bottom-full'
}

enum BUTTON_TYPE {
    BUTTON = 'button',
    SUBMIT = 'submit',
    RESET = 'reset'
}

enum B_SIZE {
    SMALL = 'sm',
    MEDIUM = 'md',
    LARGE = 'lg'
}

enum B_ALIGNMENT {
    LEFT = 'left',
    CENTER = 'center',
    RIGHT = 'right'
}

enum INPUT_TYPE {
    TEXT = 'text',
    PASSWORD = 'password',
    EMAIL = 'email',
    NUMBER = 'number',
    URL = 'url',
    TEL = 'tel',
    SEARCH = 'search',
    DATE = 'date',
    DATE_TIME = 'datetime',
    DATE_TIME_LOCAL = 'datetime-local',
    MONTH = 'month',
    WEEK = 'week',
    TIME = 'time',
    RANGE = 'range',
    COLOR = 'color'
}

enum BOUNDARY_TARGET {
    SCROLL_PARENT = 'scrollParent',
    WINDOW = 'window',
    VIEWPORT = 'viewport'
}

enum APP_LANGUAGES {
    DE = 'de',
    EN = 'en'
}

export {
    B_VARIANT,
    TOASTER_POSITION,
    BUTTON_TYPE,
    B_SIZE,
    INPUT_TYPE,
    BOUNDARY_TARGET,
    APP_LANGUAGES,
    B_ALIGNMENT,
    B_TABLE_VARIANT,
    B_TABLE_SORT
}