import Vue from "vue";
import VueI18n from "vue-i18n";
import * as Messages from "@/assets/languages";
import loadLocale from "@/plugins/vee-validate3";

Vue.use(VueI18n)

const messages = Messages.messages

const i18n = new VueI18n({
    locale: 'de',
    fallbackLocale: 'de',
    messages
})

loadLocale('de')

export function useI18n() {
    const changeLanguage = (language: string) => {
        loadLocale(language)
        i18n.locale = language;
        localStorage.setItem('localeLanguage', language)
        document.title = translate('title').toString()
    }

    return {
        changeLanguage
    }
}

export function translate(identifier: string, values?: any[] | { [key: string]: any }) {
    return i18n.t(identifier, values)
}

document.title = translate('title').toString()

export default i18n