import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import Login from "@/views/user/LoginPage.vue";
import { TOKEN_KEY } from "@/composables/useApi";
// import { ADMIN_KEY, TOKEN_KEY } from "@/composables/useAPI";


Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
    {
        path: '/',
        redirect: '/home'
    },
    {
        path: '/home',
        name: 'Home',
        meta: {
            auth: true
        },
        component: () => import(/* webpackChunkName: "Home" */ '@/views/user/HomePage.vue')
    },
    {
        path: '/templatemanagement',
        name: 'Templatemanagement',
        meta: {
            auth: true
        },
        component: () => import(/* webpackChunkName: "Templatemanagement" */ '@/views/user/TemplateManagement.vue')
    },
    {
        path: '/catalogmanagement',
        name: 'Catalogmanagement',
        meta: {
            auth: true
        },
        component: () => import(/* webpackChunkName: "Catalogmanagement" */ '@/views/user/CatalogManagement.vue')
    },
    {
        path: '/login',
        name: 'Login',
        component: Login
    },
    // {
    //     path: '/admin',
    //     name: 'LoginAdmin',
    //     component: () => import(/* webpackChunkName: "LoginAdmin" */ '@/views/admin/LoginAdmin.vue')
    // },
    // {
    //     path: '/homeAdmin',
    //     name: 'HomeAdmin',
    //     component: () => import(/* webpackChunkName: "HomeAdmin" */ '@/views/admin/HomeAdmin.vue')
    // },
    // {
    //     path: '/reset/:token',
    //     name: 'PasswordReset',
    //     component: () => import(/* webpackChunkName: "PasswordReset" */ '@/views/email/PasswordReset.vue')
    // },
    // {
    //     path: '/reset-request',
    //     name: 'PasswordResetRequest',
    //     component: () => import(/* webpackChunkName: "PasswordResetRequest" */ '@/views/email/PasswordResetRequest.vue')
    // }
]

//* Implementation if Scrolling is needed after Routing
// const headerHeight = -<insert height of header>;
// const spacerScroll = -<inset wished offset from top of element>;
// const scrollBehavior = (to: Route, from: Route, savedPosition: Position | void): PositionResult | Promise<PositionResult> | undefined | null => {
//     console.log(to.hash)
//     if (to.hash) {
//         Vue.nextTick(() => {
//             let y;
//             const el = document.getElementById(to.hash.slice(1))
//             if (el) y = el.getBoundingClientRect().top + window.pageYOffset + headerHeight + spacerScroll;
//             if (y) window.scrollTo({ top: y, behavior: 'smooth' })
//         })
//     }
//
//     return null
// }

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
    // scrollBehavior: (to, from, savedPosition) => scrollBehavior(to, from, savedPosition),
})

//*Routeguard for App with Usersystem
router.beforeEach((to, from, next) => {
//
//     const isAdmin = localStorage.getItem(ADMIN_KEY) === 'true'
    const email = localStorage.getItem(TOKEN_KEY)
//
//     //Check if Page needs auth and User is Empty --> Reroute to Login
    if (to.meta?.auth && !email) return next({ name: 'Login' })
//
//     //Check if Page needs Admin but email is not Admin --> Reroute to Admin Login
//     if (to.meta?.admin && !isAdmin) return next({ name: 'LoginAdmin' })
//
//     //Check if Target Route is Login Admin but there is a User already logged in without Admin rights
//     if (to.name === 'LoginAdmin' && email && !isAdmin) return next({ name: 'Home', })
//
//     //Check if Target Route is Login Admin but there is a logged in User with Admin rights --> Reroute to first Admin Page
//     if ((to.name === 'LoginAdmin' || to.name === 'Login') && isAdmin) return next({ name: 'HomeAdmin' })
//
//     //Check if target Route is Login but there is a logged in User --> Reroute to first User Page
//     if (to.name === 'Login' && email && !isAdmin) return next({ name: 'Home' })
//
    next()
})

export default router