import axios from "axios"
import { ADMIN_KEY, TOKEN_KEY, API_URL } from "@/composables/useApi";
import { AuthCredentials, AuthResult } from "@/types/backend/functionTypes";

let authKey = ''

export function useAuth() {
    const authRequest = async (path: string, credentials?: AuthCredentials): Promise<AuthResult | undefined> => {
        //* Here Axios so that Authorization Header is not used!!
        const response = await axios.post(API_URL + path, credentials)
            .catch((error) => {
                console.log(error)
                return undefined
            })

        if (!response) return undefined

        if (response.data?.token) {
            await setToken(response.data.token)
            // await setAdminPermission(response.data.isAdmin)
            return { valid: true }
        }
        return { valid: false }
    }

    async function setAdminPermission(permission: boolean) {
        await localStorage.setItem(ADMIN_KEY, permission.toString())
    }

    async function setToken(key: string) {
        await localStorage.setItem(TOKEN_KEY, key)

        authKey = key
    }

    async function getToken() {
        if (authKey !== '') {
            return authKey
        } else {
            const key = localStorage.getItem(TOKEN_KEY)

            if (key) {
                authKey = key
                return authKey
            }
            return 'error'
        }
    }

    return {
        authRequest,
        getToken,
        setToken
    }
}