import {
    ValidationObserver,
    ValidationProvider,
    extend,
    localize
} from "vee-validate";
import de from "vee-validate/dist/locale/de.json";
import * as rules from "vee-validate/dist/rules";
import Vue from "vue";
import { Locale } from "vue-i18n";

for (const [rule, validation] of Object.entries(rules)) {
    extend(rule, { ...validation })
}

Vue.component("ValidationObserver", ValidationObserver);
Vue.component("ValidationProvider", ValidationProvider);

const loadLocale = (code: Locale) => {
    return import(`vee-validate/dist/locale/${code}.json`).then(locale => {
        localize(code, locale);
        localize(code);
    });
}

loadLocale('de')

export default loadLocale;